var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content content-tabs",class:{ editable: _vm.editMode },attrs:{"id":_vm.content.type + '-' + _vm.content.id},on:{"click":function($event){return _vm.selectContentBlock()}}},[_c('el-tabs',{attrs:{"type":"card","tab-position":_vm.tabPosition()},on:{"tab-click":_vm.handleTabClick}},_vm._l((_vm.content.items),function(item,i){return _c('el-tab-pane',{key:i,style:({ 'background-color': item.backgroundColor })},[_c('template',{slot:"label"},[_c('div',{attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.tabTitle))},on:{"paste":_vm.pasteOnTableTitle,"blur":function($event){return _vm.changeItems({ element: 'tabTitle', value: $event.currentTarget.innerHTML, key: item.tabTitle })}}})]),_c('div',{staticClass:"row"},[(item.imagePos !== 'right')?_c('div',{staticClass:"col-sm-12",class:{ 'col-md-5': item.imagePos === 'left' },on:{"click":function($event){return _vm.setSelectedElement({ name: 'tab', field: 'image', id: i })}}},[(item.visibilityElements.image)?_c('content-block-image',{attrs:{"editMode":_vm.editMode,"image":_vm.getImage(item.image),"carousel":_vm.getCarousel(item.carousel),"imageUrl":_vm.getTranslations(item.imageUrl),"title":_vm.getTranslations(item.title)}}):_vm._e(),(item.visibilityElements.video)?_c('div',[_c('video-block',{attrs:{"pageId":_vm.pageId,"content":_vm.content,"editMode":_vm.editMode,"url":_vm.getTranslations(item.video)}})],1):_vm._e(),(item.visibilityElements.iframe)?_c('div',[_c('iframe-block',{attrs:{"source":item.iframe}})],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col-sm-12",class:{
            'col-md-7':
              item.visibilityElements.image ||
              item.visibilityElements.video ||
              (item.visibilityElements.iframe && (item.imagePos === 'left' || item.imagePos === 'right')),
            'col-md-12': item.imagePos === 'center',
            'mt-3': item.imagePos === 'center'
          }},[(item.visibilityElements.title)?_c('h3',{staticClass:"mt-3",class:{
              'mt-md-0 mt-lg-0': (!_vm.content.tabsVariant || !item.visibilityElements.image) && item.imagePos !== 'top'
            },style:({ color: item.titleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.title))},on:{"mousedown":function($event){return _vm.setSelectedElement({ name: 'tab', field: 'title', id: i })},"blur":function($event){_vm.handleBlur(
                '[id=\'' + _vm.content.type + '-' + _vm.content.id + '\'] [role=\'tabpanel\']:nth-child(' + (i + 1) + ') h3',
                'title',
                item.title,
                false,
                false,
                i
              )}}}):_vm._e(),(item.visibilityElements.subtitle)?_c('h4',{style:({ color: item.subtitleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.subtitle))},on:{"mousedown":function($event){return _vm.setSelectedElement({ name: 'tab', field: 'subtitle', id: i })},"blur":function($event){_vm.handleBlur(
                '[id=\'' + _vm.content.type + '-' + _vm.content.id + '\'] [role=\'tabpanel\']:nth-child(' + (i + 1) + ') h4',
                'subtitle',
                item.subtitle,
                false,
                false,
                i
              )}}}):_vm._e(),_c('div',{staticClass:"text-element-content-in-tab",attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.text))},on:{"mousedown":function($event){return _vm.setSelectedElement({ name: 'tab', field: 'text', id: i })},"blur":function($event){_vm.handleBlur(
                '[id=\'' +
                  _vm.content.type +
                  '-' +
                  _vm.content.id +
                  '\'] [role=\'tabpanel\']:nth-child(' +
                  (i + 1) +
                  ') .text-element-content-in-tab',
                'text',
                item.text,
                false,
                false,
                i
              )}}})]),(item.imagePos === 'right' || item.imagePos === 'bottom')?_c('div',{staticClass:"col-sm-12",class:{
            'col-md-5': item.imagePos === 'right',
            'mt-3': item.imagePos === 'bottom',
            'mb-3': item.imagePos === 'center'
          },on:{"click":function($event){return _vm.setSelectedElement({ name: 'tab', field: 'image', id: i })}}},[(item.visibilityElements.image)?_c('content-block-image',{staticClass:"something",attrs:{"editMode":_vm.editMode,"image":_vm.getImage(item.image),"carousel":_vm.getCarousel(item.carousel),"imageUrl":_vm.getTranslations(item.imageUrl),"title":_vm.getTranslations(item.title)}}):_vm._e(),(item.visibilityElements.video)?_c('div',[_c('video-block',{attrs:{"pageId":_vm.pageId,"content":_vm.content,"editMode":_vm.editMode,"url":_vm.getTranslations(item.video)}})],1):_vm._e(),(item.visibilityElements.iframe)?_c('div',[_c('iframe-block',{attrs:{"source":item.iframe}})],1):_vm._e()],1):_vm._e()])],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }