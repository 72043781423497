var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.content.type + '-' + _vm.content.id}},[_c('div',{staticClass:"content timeline-content-block",class:{ editable: _vm.editMode },on:{"click":function($event){return _vm.selectContentBlock()}}},[_c('div',{staticClass:"titles"},[(_vm.content.visibilityElements.title)?_c('h1',{class:_vm.titleClasses('title'),style:({ color: _vm.content.titleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(_vm.content.title))},on:{"mousedown":function($event){return _vm.setSelectedElement('title')},"blur":function($event){return _vm.handleBlur(
            '[id=\'' + _vm.content.type + '-' + _vm.content.id + '\'] .content .titles h1',
            'title',
            _vm.content.title,
            false,
            true
          )}}}):_vm._e(),(_vm.content.visibilityElements.subtitle)?_c('h2',{class:_vm.titleClasses('subtitle'),style:({ color: _vm.content.subtitleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(_vm.content.subtitle))},on:{"mousedown":function($event){return _vm.setSelectedElement('subtitle')},"blur":function($event){return _vm.handleBlur(
            '[id=\'' + _vm.content.type + '-' + _vm.content.id + '\'] .content .titles h2',
            'subtitle',
            _vm.content.subtitle,
            false,
            true
          )}}}):_vm._e()]),_c('div',{staticClass:"mt-5",attrs:{"id":"timeline"}},_vm._l((_vm.content.items),function(item,i){return _c('div',{key:i,staticClass:"row timeline-item",class:{ active: _vm.isActive(item) }},[_c('div',{staticClass:"col-lg-6",class:{ 'empty-card-left order-1': i % 2, 'empty-card-right order-2': !(i % 2) }},[_vm._v("   ")]),_c('div',{staticClass:"col-lg-6",class:{
            'timeline-card-right order-2': i % 2,
            'timeline-card-left order-1': !(i % 2),
            'first-without-image': !item.visibilityElements.image && i === 0,
            'without-image': !item.visibilityElements.image
          },on:{"click":function($event){return _vm.setSelectedElement({ name: 'tl', field: 'title', id: i })}}},[_c('div',{staticClass:"card timeline-content",class:{ selected: _vm.contentSelected(i, _vm.content), 'margin-without-image': !item.visibilityElements.image },style:({
              backgroundColor: item.backgroundColor
            })},[(item.visibilityElements.image)?_c('content-block-image',{staticClass:"w-100 card-img-top",attrs:{"editMode":_vm.editMode,"title":_vm.getTranslations(item.title),"image":_vm.getImage(item.image),"carousel":_vm.getCarousel(item.carousel),"imageUrl":_vm.getTranslations(item.imageUrl)}}):_vm._e(),_c('div',{staticClass:"card-body",class:item.alignment,staticStyle:{"border-top":"none"},style:({
                border:
                  '1px solid ' + (item.backgroundColor === '#FFFFFF' || '#FFFEFE' ? '#e9e9e9' : item.backgroundColor)
              })},[(item.visibilityElements.title)?_c('h3',{style:({ color: item.titleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.title))},on:{"click":function($event){return _vm.setSelectedElement({ name: 'tl', field: 'title', id: i })},"blur":function($event){_vm.handleBlur(
                    '[id=\'' +
                      _vm.content.type +
                      '-' +
                      _vm.content.id +
                      '\'] [id=\'timeline\'] .timeline-item:nth-child(' +
                      (i + 1) +
                      ') h3',
                    'title',
                    item.title,
                    false,
                    false,
                    i
                  )}}}):_vm._e(),(item.visibilityElements.subtitle)?_c('h4',{style:({ color: item.subtitleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.subtitle))},on:{"click":function($event){return _vm.setSelectedElement({ name: 'tl', field: 'subtitle', id: i })},"blur":function($event){_vm.handleBlur(
                    '[id=\'' +
                      _vm.content.type +
                      '-' +
                      _vm.content.id +
                      '\'] [id=\'timeline\'] .timeline-item:nth-child(' +
                      (i + 1) +
                      ') h4',
                    'subtitle',
                    item.subtitle,
                    false,
                    false,
                    i
                  )}}}):_vm._e(),_c('div',{staticClass:"content-box",attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.text))},on:{"click":function($event){return _vm.setSelectedElement({ name: 'tl', field: 'text', id: i })},"blur":function($event){_vm.handleBlur(
                    '[id=\'' +
                      _vm.content.type +
                      '-' +
                      _vm.content.id +
                      '\'] [id=\'timeline\'] .timeline-item:nth-child(' +
                      (i + 1) +
                      ') .card-body > div',
                    'text',
                    item.text,
                    false,
                    false,
                    i
                  )}}}),(item.visibilityElements.button)?_c('el-button',{style:(_vm.buttonStyle(item)),attrs:{"type":"info","disabled":!_vm.isActive(item) && !_vm.isInPreviewAdmin(),"size":"medium"},on:{"click":function($event){_vm.goToURL(item);
                  _vm.setSelectedElement({ name: 'tl', field: 'buttonText', id: i });}}},[_c('span',{attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.buttonText))},on:{"paste":_vm.setPlainText,"blur":function($event){return _vm.changeItems({
                      element: 'buttonText',
                      value: $event.currentTarget.innerHTML,
                      key: item.buttonText
                    })}}})]):_vm._e()],1)],1)])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }