<template>
  <div class="content" :class="{ editable: editMode }" :id="columnsId" @click="selectContentBlock()">
    <h1
      v-html="getTranslations(content.title)"
      class="columns-title"
      :class="titleClasses('title')"
      v-if="content.visibilityElements.title"
      :contenteditable="editMode"
      @mousedown="setSelectedElement('title')"
      :style="{ color: content.titleColor }"
      @blur="handleBlur('[id=\'' + columnsId + '\'] h1.columns-title', 'title', content.title, false, true)"
    ></h1>
    <h2
      v-html="getTranslations(content.subtitle)"
      class="columns-subtitle"
      :class="titleClasses('subtitle')"
      v-if="content.visibilityElements.subtitle"
      :contenteditable="editMode"
      @mousedown="setSelectedElement('subtitle')"
      :style="{ color: content.subtitleColor }"
      @blur="handleBlur('[id=\'' + columnsId + '\'] h2.columns-subtitle', 'subtitle', content.subtitle, false, true)"
    ></h2>

    <div
      class="content-block-columns"
      :class="{ 'd-none d-md-block d-lg-block': this.content.visibilityElements.swipeableOnMobile }"
    >
      <div class="row columns">
        <div
          v-for="(item, i) in content.items"
          :class="[{ 'mb-4': content.items.length > 4 }, columnsClass(i)]"
          :key="i"
          @click="setSelectedElement({ name: 'column', field: 'image', id: i })"
        >
          <div class="inner-col" :style="columnStyle(i)">
            <div :class="getImageIconClass(item)">
              <content-block-image
                v-if="item.visibilityElements.image"
                :editMode="editMode"
                :image="getImage(item.image)"
                :imageUrl="getTranslations(item.imageUrl)"
                :title="getTranslations(item.title)"
              ></content-block-image>

              <i v-if="item.visibilityElements.icon" class="material-icons" :style="iconColor(item)">{{ item.icon }}</i>
            </div>
            <h3
              :contenteditable="editMode"
              v-html="getTranslations(item.title)"
              @click="setSelectedElement({ name: 'column', field: 'title', id: i })"
              :style="{ color: item.titleColor }"
              v-if="item.visibilityElements.title"
              @blur="
                handleBlur(
                  '[id=\'' + columnsId + '\'] .row.columns div:nth-child(' + (i + 1) + ') .inner-col h3',
                  'title',
                  item.title,
                  false,
                  false,
                  i
                )
              "
            ></h3>
            <h4
              :contenteditable="editMode"
              v-html="getTranslations(item.subtitle)"
              @click="setSelectedElement({ name: 'column', field: 'subtitle', id: i })"
              :style="{ color: item.subtitleColor }"
              v-if="item.visibilityElements.subtitle"
              @blur="
                handleBlur(
                  '[id=\'' + columnsId + '\'] .row.columns div:nth-child(' + (i + 1) + ') .inner-col h4',
                  'subtitle',
                  item.subtitle,
                  false,
                  false,
                  i
                )
              "
            ></h4>
            <div
              :contenteditable="editMode"
              v-html="getTranslations(item.text)"
              @click="setSelectedElement({ name: 'column', field: 'text', id: i })"
              @blur="
                handleBlur(
                  '[id=\'' + columnsId + '\'] .row.columns div:nth-child(' + (i + 1) + ') .inner-col > div:last-child',
                  'text',
                  item.text,
                  false,
                  false,
                  i
                )
              "
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div :class="carouselClass()">
      <swiper :options="swiperOption">
        <swiper-slide
          v-for="(item, i) in content.items"
          :key="i"
          :class="carouselColumnsClass(i)"
          @click="setSelectedElement({ name: 'column', field: 'image', id: i })"
        >
          <div class="inner-col" :style="columnStyle(i)">
            <div :class="getImageIconClass(item)">
              <content-block-image
                v-if="item.visibilityElements.image"
                :editMode="editMode"
                :image="getImage(item.image)"
                :imageUrl="getTranslations(item.imageUrl)"
                :title="getTranslations(item.title)"
                :imageClass="['img-fluid']"
              ></content-block-image>
              <i v-if="item.visibilityElements.icon" class="material-icons" :style="{ color: item.iconColor }">{{
                item.icon
              }}</i>
            </div>
            <div class="mt-3 pl-2 pr-2">
              <h3
                :contenteditable="editMode"
                v-html="getTranslations(item.title)"
                @click="setSelectedElement({ name: 'column', field: 'title', id: i })"
                :style="{ color: item.titleColor }"
                v-if="item.visibilityElements.title"
                @blur="
                  handleBlur(
                    '[id=\'' + columnsId + '\'] .row.columns div:nth-child(' + (i + 1) + ') .inner-col h3',
                    'title',
                    item.title,
                    false,
                    false,
                    i
                  )
                "
              ></h3>
              <h4
                :contenteditable="editMode"
                v-html="getTranslations(item.subtitle)"
                @click="setSelectedElement({ name: 'column', field: 'subtitle', id: i })"
                :style="{ color: item.subtitleColor }"
                v-if="item.visibilityElements.subtitle"
                @blur="
                  handleBlur(
                    '[id=\'' + columnsId + '\'] .row.columns div:nth-child(' + (i + 1) + ') .inner-col h4',
                    'subtitle',
                    item.subtitle,
                    false,
                    false,
                    i
                  )
                "
              ></h4>
              <div
                :contenteditable="editMode"
                v-html="getTranslations(item.text)"
                @click="setSelectedElement({ name: 'column', field: 'text', id: i })"
                @blur="
                  handleBlur(
                    '[id=\'' +
                      columnsId +
                      '\'] .row.columns div:nth-child(' +
                      (i + 1) +
                      ') .inner-col > div:last-child',
                    'text',
                    item.text,
                    false,
                    false,
                    i
                  )
                "
              ></div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';

export default {
  extends: BaseContentComponent,
  props: {},
  data() {
    return {
      columnsId: uuid.v4(),
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        observer: true,
        observeParents: true
      }
    };
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.columnsId}"] h1.columns-title`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.columnsId}"] h2.columns-subtitle`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      this.content.items.forEach((item, index) => {
        mediumEditor(
          `[id="${this.columnsId}"] .row.columns div:nth-child(${index + 1}) .inner-col h3`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.columnsId}"] .row.columns div:nth-child(${index + 1}) .inner-col h4`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.columnsId}"] .row.columns div:nth-child(${index + 1}) .inner-col > div:last-child`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        /**
         * This is for swipe elements but it's impossible to select the text because of the swipper. ¯\_(ツ)_/¯
         */

        // mediumEditor(
        //   `[id="${this.columnsId}"] .swiper-container [data-swiper-slide-index="${index + 1}"] .inner-col h3`,
        //   ["boldStyle", "italicStyle", "bulletList", "colorPicker", "anchorLink"],
        //   (editorElement) => {
        //     this.changeItems({element: 'title', value: editorElement, key: item.title});
        //   }
        // );

        // mediumEditor(
        //   `[id="${this.columnsId}"] .swiper-container [data-swiper-slide-index="${index + 1}"] .inner-col h4`,
        //   ["boldStyle", "italicStyle", "bulletList", "colorPicker", "anchorLink"],
        //   (editorElement) => {
        //     this.changeItems({element: 'subtitle', value: editorElement, key: item.subtitle});
        //   }
        // );
        // mediumEditor(
        //   `[id="${this.columnsId}"] .swiper-container [data-swiper-slide-index="${index + 1}"] .inner-col p`,
        //   ["boldStyle", "italicStyle", "bulletList", "colorPicker", "anchorLink"],
        //   (editorElement) => {
        //     this.changeItems({element: 'text', value: editorElement, key: item.text});
        //   }
        // );
      });
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.columnsId}"] h1.columns-title`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.columnsId}"] h2.columns-subtitle`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.items.length === oldContent.items.length) {
        newContent.items.forEach((e, i) => {
          if (e.visibilityElements.title && !oldContent.items[i].visibilityElements.title) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.columnsId}"] .row.columns div:nth-child(${i + 1}) .inner-col h3`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
          if (e.visibilityElements.subtitle && !oldContent.items[i].visibilityElements.subtitle) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.columnsId}"] .row.columns div:nth-child(${i + 1}) .inner-col h4`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
        });
      }
      if (newContent.items.length > oldContent.items.length) {
        // Add editor to the new column component
        setTimeout(() => {
          mediumEditor(
            `[id="${this.columnsId}"] .row.columns div:nth-child(${newContent.items.length}) .inner-col h3`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.columnsId}"] .row.columns div:nth-child(${newContent.items.length}) .inner-col h4`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.columnsId}"] .row.columns div:nth-child(${newContent.items.length}) .inner-col > div:last-child`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  methods: {
    iconColor(item) {
      let styles = { color: item.iconColor };
      if (item.iconColor === '#FFFFFF' && item.backgroundColor === '#FFFFFF') {
        styles['background-color'] = '#000000';
      }
      return styles;
    },
    columnsClass(id) {
      let c = ['col-sm-12'];
      switch (this.content.items.length) {
        case 1:
          c.push('col-md-12');
          break;
        case 2:
          c.push('col-md-6');
          break;
        case 3:
        case 5:
        case 6:
          c.push('col-md-4');
          break;
        default:
          c.push('col-md-3');
      }
      c.push(`variant-${this.content.columnsVariant}`);
      c.push(this.content.items[id].alignment);
      return c;
    },
    carouselColumnsClass(id) {
      return [this.content.items[id].alignment];
    },
    carouselClass() {
      let c = ['d-md-none'];
      if (this.content.visibilityElements.swipeableOnMobile) {
        c.push('d-block', 'd-lg-none');
      } else {
        c.push('d-none');
      }
      return c;
    },
    columnStyle(id) {
      let style = {
        'background-color': this.content.items[id].backgroundColor
      };
      if (this.selectedContentBlock.id === this.content.id && this.selectedContentBlockElement.id == id) {
        style['outline'] = '3px solid #D9DDE0';
      }
      return style;
    },
    getImageIconClass(item) {
      let classes = [];
      if (this.content.columnsVariant === 1) {
        classes.push('smallImageIcon');
      }
      classes.push(item.imageIconPos);
      return classes;
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';
.lp-carousel .el-carousel__container {
  height: 480px;
}

.lp-carousel .el-carousel__container p {
  overflow-y: auto;
  overflow-x: hidden;
  height: 266px;
}

.columns {
  .variant-1 {
    div.smallImageIcon img {
      width: auto;
    }
  }
}

.columns {
  .col-md-12.variant-1,
  .col-md-6.variant-1 {
    div.smallImageIcon {
      height: 94px !important;

      img {
        max-height: 94px !important;
      }
    }
  }

  .col-md-4.variant-1 {
    div.smallImageIcon {
      height: 61px !important;
      img {
        max-height: 61px !important;
      }
    }
  }

  .col-md-3.variant-1 {
    div.smallImageIcon {
      height: 42px !important;
      img {
        max-height: 42px !important;
      }
    }
  }
}

.columns {
  .inner-col {
    height: 100%;
    max-height: 100%;
    border-radius: $general-border-radius;
    img {
      width: 100%;
      margin-bottom: 10px;
      border-radius: $general-border-radius $general-border-radius 0 0;
      object-fit: cover;
    }
    h3,
    h4,
    div:last-child {
      padding-left: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>

<style scoped lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

div.smallImageIcon {
  margin-bottom: 10px;
  img {
    width: auto !important;
    border-radius: 5px !important;
  }

  &.left {
    text-align: left;
    margin-left: 10px;
  }
  &.right {
    text-align: right;
    margin-right: 10px;
  }
  &.center {
    text-align: center;
  }
}

.col-md-6 {
  i.material-icons {
    font-size: 7.1em;
  }
}

.col-md-4 {
  i.material-icons {
    font-size: 4.8em;
  }
}

i.material-icons {
  font-size: 3.2em;
  &.left {
    margin-left: 10px;
  }

  &.right {
    margin-right: 10px;
  }
}

.swiper-slide {
  img {
    height: 277px;
    object-fit: cover;
  }

  .inner-col {
    margin: 3px;
  }
}
</style>
