<template>
  <div class="key-contacts-content-block" :id="keyContactsId">
    <div class="content" :class="{ editable: editMode }" :style="bgStyle()" @click="selectContentBlock()">
      <h1
        v-html="getTranslations(content.title)"
        :class="titleClasses('title')"
        v-if="content.visibilityElements.title"
        :contenteditable="editMode"
        :style="{ color: content.titleColor }"
        @mousedown="setSelectedElement('title')"
        @blur="handleBlur('[id=\'' + keyContactsId + '\'] .content h1', 'title', content.title, true, true)"
      ></h1>
      <h2
        v-html="getTranslations(content.subtitle)"
        :class="titleClasses('subtitle')"
        v-if="content.visibilityElements.subtitle"
        :contenteditable="editMode"
        :style="{ color: content.subtitleColor }"
        @mousedown="setSelectedElement('subtitle')"
        @blur="handleBlur('[id=\'' + keyContactsId + '\'] .content h2', 'subtitle', content.subtitle, true, true)"
      ></h2>
      <template v-if="loading"> <loader /></template>
      <template v-else>
        <div class="keyContacts" :class="{ oneOrThree: keyContacts.length === 1 || keyContacts.length === 3 }">
          <div
            v-for="(keyContact, i) in keyContacts"
            :key="i"
            :class="isSelectedItem(i, content)"
            class="keyContactProfile"
            @click="setSelectedElement({ name: 'kc', field: 'text', id: i })"
          >
            <div><img :src="keyContact.imageURL" /></div>
            <div class="info">
              <div class="name" :style="{ color: content.titleColor }">
                {{ keyContact.fullName }}
              </div>
              <div :style="{ color: content.subtitleColor }">
                {{ keyContact.jobTitle }}
              </div>
            </div>
            <div class="spacer"></div>
            <div>
              <button
                class="pointer material-icons"
                :title="keyContact.email"
                v-if="keyContact.email"
                aria-label="email contact"
                @click="mailTo(keyContact.email)"
                :style="{ color: style.contactIconColor }"
              >
                email
              </button>
              <button
                class="pointer material-icons ml-1"
                :title="keyContact.phoneNumberOffice"
                v-if="keyContact.phoneNumberOffice"
                aria-label="office phone contact"
                @click="callTo(keyContact.phoneNumberOffice)"
                :style="{ color: style.contactIconColor }"
              >
                phone
              </button>
              <button
                class="pointer material-icons ml-1"
                v-if="keyContact.phoneNumberMobile"
                :title="keyContact.phoneNumberMobile"
                aria-label="office mobile contact"
                @click="callTo(keyContact.phoneNumberMobile)"
                :style="{ color: style.contactIconColor }"
              >
                smartphone
              </button>
              <a
                :href="keyContact.linkedinUrl"
                target="_blank"
                v-if="keyContact.linkedinUrl"
                :title="keyContact.linkedinUrl"
                style="margin-bottom: 4px; margin-left: 0.25rem"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                  <path
                    :style="`fill:${style.contactIconColor};`"
                    d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  extends: BaseContentComponent,
  data() {
    return {
      keyContactsId: uuid.v4(),
      keyContacts: [],
      loading: false
    };
  },
  created() {
    this.getKeyContacts();
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.keyContactsId}"] .content h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.keyContactsId}"] .content h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
    }
  },
  computed: {
    ...mapGetters('learningPages', ['localeCode', 'defaultLocaleCode']),
    ...mapGetters('settings', ['style']),

    previewMode() {
      return window.location.href.indexOf('preview') >= 0 || window.location.href.indexOf('library') >= 0;
    },
    imageKeyContact() {
      return this.keyContact.imageURL || '/img/empty-image@1x.png';
    }
  },
  methods: {
    getKeyContacts() {
      if (!this.editMode && !this.previewMode) {
        this.loading = true;
        const keyContactsToFetch = this.content.items;
        this.keyContacts = [];
        let unfoundKeyContacts = 0;
        keyContactsToFetch.forEach((keyContact) => {
          Vue.prototype.$http.get(`/key-contacts/byId/${keyContact.id}`).then(
            (response) => {
              let contact = response.data;
              const id = Number(response.config.url.slice(response.config.url.lastIndexOf('/') + 1));
              contact.jobTitle = keyContactsToFetch.find((k) => k.id === id).jobTitle;
              contact.imageURL = contact.imageURL ? contact.imageURL : '/img/empty-image@1x.png';
              this.keyContacts.push(contact);
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              unfoundKeyContacts++;
              if (unfoundKeyContacts === keyContactsToFetch.length) {
                document.getElementById(this.keyContactsId).style = 'display:none';
              }
            }
          );
        });
      } else {
        let user = localStorage.getItem('admin_user');
        if (user) {
          user = JSON.parse(user);
          this.keyContacts = this.content.items.map((item, i) => {
            return {
              fullName: 'John Doe',
              jobTitle: item?.jobTitle || 'Manager',
              email: user.email || 'John.doe@talmundo.com',
              imageURL: user.imageURL || '/img/empty-image@1x.png',
              phoneNumberMobile: user.phoneNumberMobile || '+31 6 19482532',
              phoneNumberOffice: user.phoneNumberOffice || '+31 6 86568894'
            };
          });
        }
      }
    },
    mailTo(email) {
      if (!this.editMode) {
        window.location.href = `mailto:${email}`;
      }
    },
    callTo(phone) {
      if (!this.editMode) {
        window.location.href = `tel:${phone}`;
      }
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.keyContactsId}"].content h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.keyContactsId}"].content h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }

      if (!isEqual(newContent.items, oldContent.items)) {
        this.getKeyContacts();
      }
    },
    'content.items': function(newItems, oldItems) {
      this.getKeyContacts();
    }
  }
};
</script>

<style lang="scss" scoped>
.keyContacts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
}

.oneOrThree {
  grid-template-columns: 1fr;
}

.keyContactProfile {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 0fr 1fr;
  line-height: 1.9em;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;

  button {
    border: none;
    background: none;
  }

  > div {
    align-self: center;
    padding: 8px 0;
    justify-self: center;
  }

  img {
    border-radius: 50%;
    height: 48px;
  }

  .name {
    font-size: var(--title-size);
    font-weight: bold;
  }

  .info {
    margin-left: 12px;
    justify-self: left;
  }

  .spacer {
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
  }
}
</style>
