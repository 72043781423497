<template>
  <div class="content" :class="{ editable: editMode }" @click="selectContentBlock()" :id="slalomId">
    <h1
      v-html="getTranslations(content.title)"
      :class="titleClasses('title')"
      class="slalom-title"
      v-if="content.visibilityElements.title"
      :contenteditable="editMode"
      :style="{ color: content.titleColor }"
      @mousedown="setSelectedElement('title')"
      @blur="handleBlur('[id=\'' + slalomId + '\'] h1.slalom-title', 'title', content.title, false, true)"
    ></h1>
    <h2
      v-html="getTranslations(content.subtitle)"
      :class="titleClasses('subtitle')"
      class="slalom-subtitle"
      v-if="content.visibilityElements.subtitle"
      :contenteditable="editMode"
      :style="{ color: content.subtitleColor }"
      @mousedown="setSelectedElement('subtitle')"
      @blur="handleBlur('[id=\'' + slalomId + '\'] h2.slalom-subtitle', 'subtitle', content.subtitle, false, true)"
    ></h2>

    <!-- VARIANT 1 DESKTOP -->
    <div class="d-none" :class="{ 'd-sm-none d-md-block': content.variant === 0 }" id="slalom">
      <div class="col-md-12">
        <div
          v-for="(item, i) in content.items"
          :key="i"
          class="row slalom-element-container"
          @click="setSelectedElement({ name: 'slalom', field: 'item', id: i })"
          :class="isSelectedItem(i, content)"
        >
          <div class="col-md-6 col-sm-12 photo" :class="itemClass(i, 0)">
            <div :class="item.imagePos">
              <content-block-image
                v-if="item.visibilityElements.image"
                :editMode="editMode"
                :image="getImage(item.image, content.variant === 1 ? true : false)"
                :carousel="getCarousel(item.carousel)"
                :imageUrl="getTranslations(item.imageUrl)"
                :title="getTranslations(item.title)"
                :imageClass="['mt-3', ' mt-md-0', 'mt-lg-0', 'img-fluid']"
              ></content-block-image>

              <div v-if="item.visibilityElements.video">
                <video-block
                  :pageId="pageId"
                  :content="content"
                  :editMode="editMode"
                  :url="getTranslations(item.video)"
                ></video-block>
              </div>

              <div v-if="item.visibilityElements.iframe">
                <iframe-block :source="item.iframe"></iframe-block>
              </div>
            </div>
          </div>
          <div class="col-sm-12 info" :class="itemClass(i, 1, item)">
            <h3
              :contenteditable="editMode"
              v-html="getTranslations(item.title)"
              v-if="item.visibilityElements.title"
              :style="{ color: item.titleColor }"
              @blur="
                handleBlur(
                  '[id=\'' + slalomId + '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' + (i + 1) + ') h3',
                  'title',
                  item.title,
                  false,
                  false,
                  i
                )
              "
            ></h3>

            <h4
              :contenteditable="editMode"
              v-html="getTranslations(item.subtitle)"
              v-if="item.visibilityElements.subtitle"
              :style="{ color: item.subtitleColor }"
              @blur="
                handleBlur(
                  '[id=\'' + slalomId + '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' + (i + 1) + ') h4',
                  'subtitle',
                  item.subtitle,
                  false,
                  false,
                  i
                )
              "
            ></h4>

            <div
              class="slalom-element-text-container"
              :contenteditable="editMode"
              v-html="getTranslations(item.text)"
              @blur="
                handleBlur(
                  '[id=\'' +
                    slalomId +
                    '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' +
                    (i + 1) +
                    ') .slalom-element-text-container',
                  'text',
                  item.text,
                  false,
                  false,
                  i
                )
              "
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- VARIANT 1 MOBILE -->
    <div
      class="d-sm-block d-md-none"
      v-if="!this.content.visibilityElements.swipeableOnMobile && content.variant === 0"
    >
      <div class="row columns">
        <div
          v-for="(item, i) in content.items"
          class="slalom-element-container"
          :class="[{ 'mb-4': content.items.length > 4 }, columnsClass(i)]"
          :key="i"
          @click="setSelectedElement({ name: 'slalom', field: 'item', id: i })"
        >
          <div class="inner-col" :style="columnStyle(i)">
            <div style="text-align: center">
              <content-block-image
                v-if="item.visibilityElements.image"
                :editMode="editMode"
                :image="getImage(item.image)"
                :carousel="getCarousel(item.carousel)"
                :imageUrl="getTranslations(item.imageUrl)"
                :title="getTranslations(item.title)"
                :imageClass="['img-fluid']"
              ></content-block-image>
              <div v-if="item.visibilityElements.video">
                <video-block
                  class="mb-2"
                  :pageId="pageId"
                  :content="content"
                  :editMode="editMode"
                  :url="getTranslations(item.video)"
                ></video-block>
              </div>

              <div v-if="item.visibilityElements.iframe">
                <iframe-block class="mb-2" :source="item.iframe"></iframe-block>
              </div>
            </div>
            <h3
              :contenteditable="editMode"
              v-html="getTranslations(item.title)"
              @click="setSelectedElement({ name: 'slalom', field: 'title', id: i })"
              @blur="
                handleBlur(
                  '[id=\'' + slalomId + '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' + (i + 1) + ') h3',
                  'title',
                  item.title,
                  true,
                  false,
                  i
                )
              "
              :style="{ color: item.titleColor }"
              v-if="item.visibilityElements.title"
            ></h3>
            <h4
              :contenteditable="editMode"
              v-html="getTranslations(item.subtitle)"
              @click="setSelectedElement({ name: 'slalom', field: 'subtitle', id: i })"
              @blur="
                handleBlur(
                  '[id=\'' + slalomId + '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' + (i + 1) + ') h4',
                  'subtitle',
                  item.subtitle,
                  true,
                  false,
                  i
                )
              "
              :style="{ color: item.subtitleColor }"
              v-if="item.visibilityElements.subtitle"
            ></h4>
            <div
              class="slalom-element-text-container"
              :contenteditable="editMode"
              v-html="getTranslations(item.text)"
              @blur="
                handleBlur(
                  '[id=\'' +
                    slalomId +
                    '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' +
                    (i + 1) +
                    ') .slalom-element-text-container',
                  'text',
                  item.text,
                  false,
                  false,
                  i
                )
              "
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- SWIPE ON MOBILE -->
    <div :class="carouselClass()" v-if="this.content.visibilityElements.swipeableOnMobile">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, i) in content.items" :key="i" class="slalom-element-container">
          <div class="col-sm-12 photo">
            <div
              class="col-md-6 col-sm-12 photo"
              :class="itemClass(i, 0)"
              @click="setSelectedElement({ name: 'slalom', field: 'image', id: i })"
            >
              <content-block-image
                v-if="item.visibilityElements.image"
                :editMode="editMode"
                :image="getImage(item.image)"
                :carousel="getCarousel(item.carousel)"
                :imageUrl="getTranslations(item.imageUrl)"
                :title="getTranslations(item.title)"
                :imageClass="['img-fluid']"
              ></content-block-image>
              <div v-if="item.visibilityElements.video">
                <video-block
                  :pageId="pageId"
                  :content="content"
                  :editMode="editMode"
                  :url="getTranslations(item.video)"
                ></video-block>
              </div>

              <div v-if="item.visibilityElements.iframe">
                <iframe-block :source="item.iframe"></iframe-block>
              </div>
            </div>
            <div class="info">
              <h3
                :contenteditable="editMode"
                v-html="getTranslations(item.title)"
                @click="setSelectedElement({ name: 'slalom', field: 'title', id: i })"
                @blur="
                  handleBlur(
                    '[id=\'' + slalomId + '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' + (i + 1) + ') h3',
                    'title',
                    item.title,
                    true,
                    false,
                    i
                  )
                "
                :style="{ color: item.titleColor }"
                v-if="item.visibilityElements.title"
              ></h3>
              <h4
                :contenteditable="editMode"
                v-html="getTranslations(item.subtitle)"
                @click="setSelectedElement({ name: 'slalom', field: 'subtitle', id: i })"
                @blur="
                  handleBlur(
                    '[id=\'' + slalomId + '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' + (i + 1) + ') h4',
                    'subtitle',
                    item.subtitle,
                    true,
                    false,
                    i
                  )
                "
                :style="{ color: item.subtitleColor }"
                v-if="item.visibilityElements.subtitle"
              ></h4>

              <div
                class="slalom-element-text-container"
                :contenteditable="editMode"
                v-html="getTranslations(item.text)"
                @click="setSelectedElement({ name: 'slalom', field: 'text', id: i })"
                :style="{ color: item.textColor }"
                @blur="
                  handleBlur(
                    '[id=\'' +
                      slalomId +
                      '\'] [id=\'slalom\'] .slalom-element-container:nth-child(' +
                      (i + 1) +
                      ') .slalom-element-text-container',
                    'text',
                    item.text,
                    false,
                    false,
                    i
                  )
                "
              ></div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Button } from 'element-ui';
import BaseContentComponent from './BaseContentComponent.vue';
import VideoBlock from '@lp/components/content-blocks/VideoBlock.vue';
import IframeBlock from '@lp/components/content-blocks/IframeBlock.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';
import GlobalEvents from 'vue-global-events';

export default {
  extends: BaseContentComponent,
  components: {
    elButton: Button,
    VideoBlock,
    IframeBlock,
    GlobalEvents
  },
  data() {
    return {
      slalomId: uuid.v4(),
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        observer: true,
        observeParents: true
      }
    };
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.slalomId}"] h1.slalom-title`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.slalomId}"] h2.slalom-subtitle`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      this.content.items.forEach((item, index) => {
        mediumEditor(
          `[id="${this.slalomId}"] [id="slalom"] .slalom-element-container:nth-child(${index + 1}) h3`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.slalomId}"] [id="slalom"] .slalom-element-container:nth-child(${index + 1}) h4`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.slalomId}"] [id="slalom"] .slalom-element-container:nth-child(${index +
            1}) .slalom-element-text-container`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
      });
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.slalomId}"] h1.slalom-title`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.slalomId}"] h2.slalom-subtitle`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.items.length === oldContent.items.length) {
        newContent.items.forEach((e, i) => {
          if (e.visibilityElements.title && !oldContent.items[i].visibilityElements.title) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.slalomId}"] [id="slalom"] .slalom-element-container:nth-child(${i + 1}) h3`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
          if (e.visibilityElements.subtitle && !oldContent.items[i].visibilityElements.subtitle) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.slalomId}"] [id="slalom"] .slalom-element-container:nth-child(${i + 1}) h4`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
        });
      }
      if (newContent.items.length > oldContent.items.length) {
        // Add editor to the new accordion componentç
        setTimeout(() => {
          mediumEditor(
            `[id="${this.slalomId}"] [id="slalom"] .slalom-element-container:nth-child(${newContent.items.length}) h3`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.slalomId}"] [id="slalom"] .slalom-element-container:nth-child(${newContent.items.length}) h4`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.slalomId}"] [id="slalom"] .slalom-element-container:nth-child(${newContent.items.length}) .slalom-element-text-container`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  methods: {
    columnStyle(id) {
      let style = {
        'background-color': this.content.items[id].backgroundColor
      };
      if (this.selectedContentBlock.id === this.content.id && this.selectedContentBlockElement.id == id) {
        style['outline'] = '3px solid #D9DDE0';
      }
      return style;
    },
    columnsClass(id) {
      let c = ['col-sm-12'];
      switch (this.content.items.length) {
        case 1:
          c.push('col-md-12');
          break;
        case 2:
          c.push('col-md-6');
          break;
        case 3:
          c.push('col-md-4');
          break;
        default:
          c.push('col-md-3');
      }
      c.push(this.content.items[id].alignment);
      return c;
    },
    itemClass(index, col, item) {
      let classes = [];
      if (item) {
        if (item.visibilityElements.image || item.visibilityElements.video || item.visibilityElements.iframe) {
          classes.push('col-md-6');
        }

        if (item.alignment) {
          classes.push(item.alignment);
        }
      }

      (index + col) % 2 ? classes.push('order-2') : classes.push('order-1');
      return classes;
    },
    carouselClass() {
      let c = ['d-md-none'];
      if (this.content.visibilityElements.swipeableOnMobile) {
        c.push('d-block', 'd-lg-none');
      } else {
        c.push('d-sm-none');
      }
      return c;
    }
  }
};
</script>

<style lang="scss">
#slalom {
  img {
    width: 100%;
    object-fit: cover;
    padding: 3px;
  }

  .stretch {
    height: 100%;
  }

  .stretch img {
    width: 100%;
    height: 100%;
    padding: 0px;
  }
}
</style>

<style scoped lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

.swiper-pagination-bullets {
  top: 5px;
}

.content {
  margin-bottom: 40px;
}
.photo {
  img {
    border-width: 0;
    width: 100%;
  }
  padding: 0;
}

.horizontal-scrolling-wrapper {
  overflow-y: hidden;
  .info {
    padding: 200px 10px 10px 10px;
  }

  .bgImage {
    height: 300px;
    width: 240px;
  }
}

.columns {
  .col-md-12,
  .col-md-6 {
    .bgImage {
      height: 400px;
    }

    .info {
      padding: 300px 10px 10px 10px;
    }
  }

  .col-md-4 {
    .bgImage {
      height: 300px;
    }

    .info {
      padding: 200px 10px 10px 10px;
    }
  }

  .col-md-3 .bgImage {
    height: 225px;
  }
}

#slalom {
  .screen.phone {
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-md-12 {
      padding: 0 5px;
    }
    .items-sm {
      display: block !important;
    }
    .items-md {
      display: none !important;
    }
    .info,
    .photo {
      order: 0;
    }
  }
  .screen.desktop,
  .screen.tablet {
    .col-sm-12 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .items-md {
      display: block !important;
    }
    .items-sm {
      display: none !important;
    }
    .col-md-12 {
      padding: 0 5px;
    }
  }
}
div.row {
  padding: 0;
}
.info {
  margin-top: 1.2rem;
}
div.row.selected {
  border: 2px solid $color-blue;
}
.contact {
  padding-bottom: 1em;
  display: inline-block;
  i {
    font-size: var(--title-size);
    letter-spacing: 17px;
  }
}

.horizontal-scrolling-wrapper {
  img {
    height: 177px;
    object-fit: cover;
  }
}

.swiper-slide {
  img {
    height: 277px;
    object-fit: cover;
    border-radius: $general-border-radius $general-border-radius 0 0;
  }
}

.screen.phone {
  .columns img {
    height: 282px;
  }
}

.columns {
  .col-md-12 img,
  .col-md-6 img {
    height: 282px;
  }

  .col-md-4 img {
    height: 184px;
  }

  .col-md-3 img {
    height: 128px;
  }

  .inner-col {
    border-radius: $general-border-radius;
    img {
      width: 100%;
      margin-bottom: 10px;
      border-radius: $general-border-radius $general-border-radius 0 0;
      object-fit: cover;
    }
    h3,
    h4,
    p {
      padding-left: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.bottom {
  position: relative;
  top: 100%;
  transform: translateY(-100%);
}
</style>
